import React from 'react';
import { Container, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import classnames from 'classnames';
import ApprovalScreen from './ApprovalComponent';
import './Approval.scss';
import { APPROVAL_HEAD } from '../../../Constants/AppConstants';
import { withNamespaces } from 'react-i18next';
import { URLS } from '../../../HOC/Communication/ApiUrls';
import { axiosInstance } from '../../../HOC/Communication';
import { MODAL_SIZE, withModal } from '../../../HOC/Modal';
import PopupModal from '../../Common/PopupModal/PopupModal';

class ApprovalBaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: APPROVAL_HEAD[0].activeTab,
      list: null,
      pageInfo: null,
      pageSize: 0,
      size: 10,
      noData: false,
      isLoading: true, // Track loading state
      searchText: null,
      showSearch: false,
      deptId: null,
      deptList: null,
      selectedDept:null
    };
  }

  componentDidMount() {
    this.axios = axiosInstance;
    this.getApprovals(this.state.activeTab, this.state.pageSize, this.state.size);
    this.getDepartments();
    this.props.handelBackButton();
  }

  getApprovals = (status = this.state.activeTab, page = this.state.pageSize, size = this.state.size, searchText = this.state.searchText, deptId = this.state.deptId) => {
    this.setState({ noData: false, isLoading: true });
    this.props.showModal(<Spinner />);

    this.axios.post(URLS.GET_APPROVALS, {
      status, page, size, query: searchText, deptId,
    })
    .then((res) => {
      this.props.closeModal();
      this.setState({ isLoading: false });

      if (!res.isError) {
        const list = res.data.data.data;
        const pageInfo = res.data.data;
        this.setState({ list, pageInfo, noData: list.length === 0 });
      } else {
        this.setState({ list: [], noData: true });
      }
    });
  };

  getDepartments = () => {
    this.axios.get(URLS.GET_DEPARTMENT)
      .then((res) => {
        if (!res.isError) {
          let  deptList = res.data.data;
          deptList = [{ deptId: 0, deptName: "All" }, ...deptList];
          this.setState({ deptList });
        } else {
          this.setState({ deptList: null });
        }
      });
  };

  handlePageChange = (newPage) => {
    this.setState({ pageSize: newPage - 1 }, () => {
      this.getApprovals(this.state.activeTab, this.state.pageSize, this.state.size);
    });
  };

  editStatus = (id, status, remark) => {
    this.props.showModal(<Spinner />);
    const data = { status, remarks: remark };

    this.axios.put(`${URLS.GET_APPROVALS}${id}/status`, data)
      .then((res) => {
        this.props.closeModal();
        if (!res.isError) {
          this.setState({ searchText: null });
          setTimeout(() => {
            this.props.showModal(
              <PopupModal
                title="modal.success"
                message="modal.successMessage.statusUpdation"
                onOkClick={() => this.getApprovals()}
              />,
              MODAL_SIZE.SM
            );
          }, 200);
        }
      });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, searchText: null, showSearch: false ,deptId:null});
      this.getApprovals(tab, 0, this.state.size,null,null);
    }
  };

  renderApprovalTab = () => {
    return APPROVAL_HEAD.map((element, index) => (
      <NavItem key={index}>
        <NavLink
          className={classnames({ active: this.state.activeTab === element.activeTab })}
          onClick={() => this.toggleTab(element.activeTab)}
        >
          {this.props.i18n.t(element.TEXT)}
        </NavLink>
      </NavItem>
    ));
  };

  handleSearchChange = (event) => {
    this.setState({ searchText: event.target.value });
    if (event.target.value === '') {
      this.setState({ showSearch: true });
      this.setState({ searchText: null });
    }
  };


  handleDepartmentChange = (event) => {
    const selectedDeptId = event.target.value;
    this.setState({selectedDept:selectedDeptId})
    this.setState({ deptId: selectedDeptId === "0" || "" ? null : selectedDeptId });
  };
  
  handleSearchSubmit = () => {
    const { searchText, deptId } = this.state;
    this.getApprovals(this.state.activeTab, 0, this.state.size, searchText, deptId);
  };

  render() {
    const { searchText, list, isLoading, showSearch, deptList } = this.state;
    const shouldShowSearchBar = searchText || (Array.isArray(list) && list.length !== 0) || showSearch;

    return (
      <div className="base-container my-3">
        <div className="base-component">
          <Nav tabs className="mb-3">
            {this.renderApprovalTab()}
          </Nav>

          {!isLoading && shouldShowSearchBar && (
            <div className="search-bar text-field">
              {this.state.activeTab === APPROVAL_HEAD[0].activeTab && deptList && (
                <div className="select-wrapper form-control input-field">
                <select 
                  value={this.state.deptId}
                  onChange={this.handleDepartmentChange}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      this.handleSearchSubmit();
                    }
                  }}
                  
                >
                  <option value="" hidden={this.state.selectedDept!== ""}> 
                  {this.state.selectedDept === "0" ? "All" : "Select Department"}  </option> 
                  {deptList.map((department, index) => (
                    <option key={index} value={department.deptId}>
                      {department.deptName}
                    </option>
                  ))}
                </select>
                </div>
              )}
              <input
                type="text"
                placeholder="Search request by employee ID or name"
                value={searchText || ''}
                onChange={this.handleSearchChange}
                className="input-field form-control"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    this.handleSearchSubmit();
                  }
                }}
              />
              <button className="btn btn-secondary search-btn" onClick={this.handleSearchSubmit}>Search</button>
            </div>
          )}

          {!isLoading && Array.isArray(list) && list.length !== 0 ? (
            <Container fluid>
              <ApprovalScreen
                activeTab={this.state.activeTab}
                list={this.state.list}
                pageInfo={this.state.pageInfo}
                handlePageChange={this.handlePageChange}
                editStatus={this.editStatus}
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
              />
            </Container>
          ) : (
            !isLoading && this.state.noData && (
              <div className="nodata">
                {this.state.activeTab === APPROVAL_HEAD[0].activeTab
                  ? this.props.i18n.t('common.noPending')
                  : this.state.activeTab === APPROVAL_HEAD[1].activeTab
                    ? this.props.i18n.t('common.noApproval')
                    : this.props.i18n.t('common.noRejected')}
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

export default withNamespaces()(withModal(ApprovalBaseComponent));
